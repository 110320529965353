<template>
  <div class="container mw-fit-content">
    <Table
      :thead="thead"
      :data="data"
      :searchBox="true"
      :excel="userGroups.includes('CustomerUser') ? false : true"
      tableTitle="Uygulamalar"
    >
      <template
        v-slot:button
        v-if="!this.$store.state.auth.user.groups.includes('CustomerUser')"
      >
        <router-link :to="{ name: 'AppCreate' }" class="btn btn-filled-primary">
          Uygulama Ekle
          <i class="icon icon-add ml-10"></i>
        </router-link>
      </template>
      <template v-slot:cell-edit="{ row: data }">
        <router-link
          :to="{ name: 'AppEdit', params: { id: data.app_id } }"
          class="btn btn-empty-primary minw-auto"
        >
          <i class="icon icon-message-edit"></i>
        </router-link>
      </template>
      <template v-slot:cell-notification="{ row: data }">
        <router-link
          :to="{ name: 'MobileNotification', params: { id: data.app_id } }"
          class="btn btn-empty-primary minw-auto"
        >
          <i class="icon icon-notification ml-10"></i>
        </router-link>
      </template>
      <template v-slot:cell-client_id="{ row: data }">
        <a
          v-if="data.client_id"
          :href="
            this.$env.stage.API_URL +
            '/ideasoft/login/' +
            data.app_id +
            '/' +
            data.client_id +
            '/'
          "
          class="btn btn-empty-primary"
          target="_blank"
        >
          <i class="icon icon-arrow-3"></i>
        </a>
        <a
          href="javascript:void(0)"
          style="opacity: 0.1"
          class="btn btn-empty-primary"
          v-else
          ><i class="icon icon-arrow-3"></i
        ></a>
      </template>

      <template v-slot:cell-app_id="{ row: data }">
        {{ data.app_id }}
      </template>
      <template v-slot:cell-name="{ row: data }">
        {{ data.name }}
      </template>
      <template v-slot:cell-store_url="{ row: data }">
        {{ data.store_url }}
      </template>
      <template v-slot:cell-ios_status="{ row: data }">
        <span :class="'app_status app_status_' + data.ios_status">
          {{ data.ios_status }}
        </span>
      </template>
      <template v-slot:cell-android_status="{ row: data }">
        <span :class="'app_status app_status_' + data.android_status">
          {{ data.android_status }}
        </span>
      </template>
      <template v-slot:cell-delete="{ row: data }">
        <button class="btn btn-empty-danger" @click="deleteApp(data.app_id)">
          <i class="icon icon-trash"></i>
        </button>
      </template>
    </Table>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {},
  created() {
    this.getData();
    this.$store.commit("theme/addToBreadcrumps", [
      {
        title: "Anasayfa",
        path: "/",
      },
      {
        title: "Mobil Uygulamalar",
        path: "/apps",
      },
    ]);
  },
  methods: {
    async deleteApp(id) {
      await this.$axios.delete(`/mobile-app/${id}/`).then(() => {
        this.getData();
      });
    },
    async getData() {
      this.$axios.get("/mobile-app/").then((response) => {
        this.data = [];
        response.data.filter((app) => {
          this.data.push({
            app_id: app.id,
            name: app.name,
            store_url: app.ecommerce_store.store_url,
            client_id: app.ideasoft_store_attributes
              ? app.ideasoft_store_attributes.oauth_client_id
              : "",
            ios_status: app.ios_attributes ? app.ios_attributes.status : "none",
            android_status: app.android_attributes
              ? app.android_attributes.status
              : "none",
          });
        });
      });
    },
  },
  setup() {
    const store = useStore();
    let userGroups = store.state.auth.user.groups;
    let thead;
    !userGroups.includes("CustomerUser")
      ? (thead = [
          {
            label: "Düzenle",
            value: "edit",
            searchable: false,
            exports: false,
          },
          {
            label: "İdeasoft Token",
            value: "client_id",
            searchable: false,
            sortable: false,
          },
          {
            label: "Uygulama ID",
            value: "app_id",
            searchable: true,
            sortable: false,
          },
          {
            label: "Uygulama Adı",
            value: "name",
            searchable: true,
            sortable: true,
          },
          {
            label: "Site Adresi",
            value: "store_url",
            searchable: true,
            sortable: true,
          },
          {
            label: "IOS Uygulama Durumu",
            value: "ios_status",
            searchable: true,
            sortable: true,
          },
          {
            label: "Android Uygulama Durumu",
            value: "android_status",
            searchable: true,
            sortable: true,
          },
          {
            label: "Sil",
            value: "delete",
            searchable: false,
            sortable: false,
          },
        ])
      : (thead = [
          {
            label: "Bildirim Gönder",
            value: "notification",
            searchable: false,
            exports: false,
          },
          {
            label: "Uygulama Adı",
            value: "name",
            searchable: true,
            sortable: true,
          },
        ]);

    return {
      thead,
      userGroups,
    };
  },
};
</script>